import { DEFAULT_CURRENCY } from 'core/constants/currency.constants';
import { EDistributorSource } from 'core/model/enums/checkout.enum';
import { ICheckoutDetail } from 'core/model/interfaces/checkout.interface';
import { ICartProduct } from 'core/model/interfaces/product.interface';
import { hasReducedPrice } from 'shared/utils/products.utils';
import { ICheckoutCartDetailDTO, IDeliveryGroupingRuleItemDTO } from './checkout.dto';

export const mapToCheckoutDetail = (data: ICheckoutCartDetailDTO): ICheckoutDetail => ({
  alicorpCart: mapSource(data, EDistributorSource.ALICORP),
  alliedCart: mapSource(data, EDistributorSource.ALLIED),
  generalCart: mapSource(data, EDistributorSource.GENERAL),
  amounts: data.totals,
  deletedProducts: data.deletedProductsByStock
    ? data.deletedProductsByStock.map(dp => ({
        displayName: dp.commercialName,
        sku: dp.id,
        image: dp.image,
        presentation: dp.presentation,
      }))
    : [],
});

const mapSource = (data: ICheckoutCartDetailDTO, distributor: EDistributorSource) => {
  const detail = data[distributor];
  return {
    amounts: detail?.totals,
    groupingRules: (detail?.deliveryGroupingRules || []).map(rule => ({
      id: rule.id,
      description: rule.description,
      dates: rule.availableDeliveryDates,
      products: rule.items.map(mapGroupingRuleProduct),
      distributor,
    })),
  };
};

const mapGroupingRuleProduct = (item: IDeliveryGroupingRuleItemDTO): ICartProduct => {
  const promos = item.promotions
    ? item.promotions.map(promo => ({
        id: promo.id,
        name: promo.description,
        value: promo.discount,
      }))
    : [];

  return {
    amounts: {
      base: item.totals.base,
      final: item.totals.final,
      igv: item.totals.igv,
      perception: item.totals.perception,
      price: item.totals.price,
      discount: item.totals.discount,
    },
    brand: item.product.brand,
    categoryId: Number(item.product.externalCategory.id),
    categoryName: item.product.externalCategory.name,
    distributorName: item.product.externalDistributor.name,
    currency: DEFAULT_CURRENCY,
    description: item.product.description,
    promos,
    eanCode: item.product.eanCode,
    image: item.product.image,
    maximumSaleQuantity: item.product.unitMeasure.maximumSaleQuantity,
    minimumSaleQuantity: item.product.unitMeasure.minimumSaleQuantity,
    name: item.product.name,
    commercialName: item.product.commercialName,
    presentation: item.product.unitMeasure.presentation,
    price: item.product.unitMeasure.price,
    originalPrice: item.product.unitMeasure.originalPrice,
    hasDiscount: hasReducedPrice(promos),
    providerDisplayName: item.product.externalProvider.name,
    quantity: item.quantity,
    sku: item.product.sku,
    sourceId: item.product.sourceId,
    stock: item.product.stock,
    subcategoryId: Number(item.product.externalFamily.id),
    subcategoryName: item.product.externalFamily.name,
    reducedQuantity: item.quantityReducedByStock || 0,
    taxRate: 0,
    selectedUnitMeasure: {
      code: item.product.unitMeasure.code,
      default: item.product.unitMeasure.default,
      name: item.product.unitMeasure.description,
      presentation: item.product.unitMeasure.presentation,
      conversionFactor: item.product.unitMeasure.conversionFactor,
      maximumSaleQuantity: item.product.unitMeasure.maximumSaleQuantity,
      minimumSaleQuantity: item.product.unitMeasure.minimumSaleQuantity,
      originalPrice: item.product.unitMeasure.originalPrice,
      price: item.product.unitMeasure.price,
      hasDiscount: item.product.unitMeasure.hasDiscount,
    },
    metadata: {
      index: item.metadata?.index ?? 1,
      itemListName: item.metadata?.itemListName ?? '',
      origin: item.metadata?.origin ?? '',
    },
  };
};
