import { Drawer } from 'shared/components/drawer';

interface IMobileSelectorMultipleAddressProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export const MobileSelectorMultipleAddress = ({ children, isOpen, onClose }: IMobileSelectorMultipleAddressProps) => (
  <Drawer visible={isOpen} direction="up" close={onClose} shouldUnmountChildren>
    {children}
  </Drawer>
);
