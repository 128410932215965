import { getEnvValue } from 'shared/utils/env.utils';

export const NO_PRODUCTS_FOUND = 'No se encontraron productos';

export const NO_PRODUCT_FOUND = 'No se encontró el producto';

export const TRY_FOUND_ANOTHER_PRODUCT = 'Intenta buscando otro producto.';

export const ENVIRONMENT = getEnvValue('VITE_ENV');

export const GTM_ID = getEnvValue('VITE_GTM_ID');

export const IMAGES_ROUTES = getEnvValue('VITE_API_URL_IMAGES_ROUTES');

export const APPLICATION_ID = getEnvValue('VITE_HEADER_APPLICATION_ID');

export const BASE_URL = getEnvValue('VITE_BASE_URL');

export const COOKIEBOT_ID = getEnvValue('VITE_COOKIEBOT_ID');

export const META_PIXEL_CODE = getEnvValue('VITE_META_PIXEL_CODE');

export const VWO_ACCOUNT_ID = getEnvValue('VITE_VWO_ACCOUNT_ID');

export const CLARITY_PROJECT_ID = getEnvValue('VITE_CLARITY_PROJECT_ID');

export const API_URL = getEnvValue('VITE_API_URL');

export const MSAL_BASE_URL = getEnvValue('VITE_MSAL_BASE_URL');

export const MSAL_TENANT_NAME = getEnvValue('VITE_MSAL_TENANT_NAME');

export const MSAL_CLIENT_ID = getEnvValue('VITE_MSAL_CLIENT_ID');

export const MSAL_REDIRECTION_URI = getEnvValue('VITE_MSAL_REDIRECT_URI');

export const MSAL_POSTLOGOUT_REDIRECTION_URI = getEnvValue('VITE_MSAL_POSTLOGOUT_REDIRECT_URI');

export const TRACEPARENT_VERSION_ID = getEnvValue('VITE_TRACEPARENT_VERSION_ID');

export const TRACEPARENT_FLAGS_ID = getEnvValue('VITE_TRACEPARENT_FLAGS_ID');

export const API_RESPONSE_MS_TIMEOUT = getEnvValue('VITE_API_RESPONSE_MS_TIMEOUT');

export const DEFAULT_API_RESPONSE_MS_TIMEOUT = 60_000;

export const WHATSAPP_ORDER_AGENT_NUMBER = getEnvValue('VITE_WHATSAPP_ORDER_AGENT_NUMBER');

export const FINGERPRINT_API_KEY = getEnvValue('VITE_FINGERPRINT_API_KEY');
