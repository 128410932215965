import { sellInsEvents } from 'core/constants/insider.constants';
import { EInsiderPageType } from 'core/model/enums/insider.enum';
import { ICustomer } from 'core/model/interfaces/customer.interface';
import { IInsiderEvents, IRegisterInsider } from 'core/model/interfaces/insider.interface';
import { IOrderCreated } from 'core/model/interfaces/order.interface';
import { ICartProduct, IDetailProduct, IProduct } from 'core/model/interfaces/product.interface';
import { isLocalEnv } from './env.utils';
import {
  cartToInsiderCart,
  cutomerToInsiderUser,
  orderDetailToInsiderTransaction,
  productDetailToInsiderProduct,
  productListToInsiderProductList,
} from './insider.mapper';

const canRunInLocalEnv = isLocalEnv();

export const initializeInsider = () => {
  const { Insider } = window;

  if (Insider) {
    if (Insider.eventManager) {
      Insider.eventManager.dispatch?.('init-manager:re-initialize');
    } else {
      // eslint-disable-next-line no-console
      console.warn(`There is not eventManager in Insider`);
    }
  }

  // eslint-disable-next-line no-console
  else console.warn(`There is not Insider`);
};

export const settingInsiderPageType = (type: EInsiderPageType) => {
  window.insider_object = {
    ...window.insider_object,
    page: {
      type,
    },
  };

  if (canRunInLocalEnv) {
    // eslint-disable-next-line no-console
    console.log(`Actualización del Page Object en Insider:`, window.insider_object.page);
  }
};

export const cleanInsiderPageType = () => {
  window.insider_object = {
    ...window.insider_object,
    page: {},
  };
};

export const setInsertObject = (customer: ICustomer) => {
  window.insider_object = {
    user: cutomerToInsiderUser(customer),
  };

  if (canRunInLocalEnv) {
    // eslint-disable-next-line no-console
    console.log('Actualización del User Object en Insider:', window.insider_object.user);
  }
};

export const setInsiderProduct = (product: IDetailProduct) => {
  window.insider_object = {
    ...window.insider_object,
    product: productDetailToInsiderProduct(product),
  };

  if (canRunInLocalEnv) {
    // eslint-disable-next-line no-console
    console.log('Actualización del Product Object en Insider:', window.insider_object.product);
  }
};

export const setInsiderProductList = (products: Array<IProduct>) => {
  window.insider_object = {
    ...window.insider_object,
    listing: {
      items: productListToInsiderProductList(products),
    },
  };

  if (canRunInLocalEnv) {
    // eslint-disable-next-line no-console
    console.log('Actualización del Listing Object en Insider:', window.insider_object.listing);
  }
};

export const setInsiderCart = (products: Array<ICartProduct>, currency: string, total: number) => {
  window.insider_object = {
    ...window.insider_object,
    basket: cartToInsiderCart(products, currency, total),
  };

  if (canRunInLocalEnv) {
    // eslint-disable-next-line no-console
    console.log('Actualización del Basket Object en Insider:', window.insider_object.basket);
  }
};

export const setInsiderTransaction = (orders: Array<IOrderCreated>) => {
  window.insider_object = {
    ...window.insider_object,
    transaction: orderDetailToInsiderTransaction(orders),
  };

  if (canRunInLocalEnv) {
    // eslint-disable-next-line no-console
    console.log('Actualización del Transaction Object en Insider:', window.insider_object.transaction);
  }
};

export const eventsInsider = () => {
  const dataEvents = new Map<string, Map<string, any>>([['sellIns', sellInsEvents]]);

  const register = ({ space = '', event = '', props = {}, actionName }: IRegisterInsider) => {
    const { Insider } = window;
    const dataDefault = { ...dataEvents.get(space)?.get(event) };
    const data: IInsiderEvents = {
      ...dataDefault,
      ...(actionName && {
        [actionName]: {
          ...dataDefault[actionName],
          ...props,
        },
      }),
    };
    Insider?.track('events', [data]);
    if (canRunInLocalEnv) {
      // eslint-disable-next-line no-console
      console.log(`Evento a Insider: ${data}):`, data);
    }
  };

  return {
    register,
  };
};
