import { Skeleton } from '@insuma/mpp-ui/components/skeleton';
import { FIRST_LOAD_CATALOG_CAROUSEL_SKELETON } from 'core/constants/skeletons.constants';
import { ProductCardsSkeleton } from '../product-cards-skeleton';

import './carousel-skeleton.scss';

const CarouselSkeleton = () => (
  <div className="carousel-skeleton">
    <Skeleton
      style={{
        width: '14.4rem',
        height: '2rem',
      }}
      className="carousel-skeleton__title"
    />
    <div className="carousel-skeleton__items">
      {[...Array(FIRST_LOAD_CATALOG_CAROUSEL_SKELETON)].map((_, index) => (
        <ProductCardsSkeleton key={`product-skeleton-${index}`} />
      ))}
    </div>
  </div>
);

export default CarouselSkeleton;
