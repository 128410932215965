import Slider from 'react-slick';
import useWindowSize from 'shared/hooks/use-window-size';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slick-slider.scss';

interface ISlickSliderProps {
  items: Array<JSX.Element>;
}

export const SlickSlider = ({ items }: ISlickSliderProps) => {
  const { isDesktop } = useWindowSize();
  const settings = {
    rows: 1,
    className: isDesktop ? 'slider-desktop' : 'slider-mobile',
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: isDesktop,
    draggable: false,
    swipeToSlide: !isDesktop,
  };

  return <Slider {...settings}>{items}</Slider>;
};
