export const formatNumberWithCommas = (x: number, decimals = 2): string => {
  if (x == null) {
    return '0.00';
  }
  const constantFactor = 10 ** decimals;
  const numberFixed = Math.round(x * constantFactor) / constantFactor;

  const parts = numberFixed?.toFixed(decimals).split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const validateDecimalNumber = (x: number): number => {
  const decimalNumber = x % 1;
  if (decimalNumber > 0) {
    return x;
  }
  const wholeNumber = Math.floor(x);
  return wholeNumber;
};
