import { WHATSAPP_CUSTOMER_SERVICE_LINK } from './external.constants';

export const Path = {
  // LOGIN
  LOGIN: '/',
  LOGOUT: '/logout',

  // SUPPLYING
  HOME: '/inicio',
  CATALOG: '/catalogo',
  CHECKOUT: '/confirmar',
  CHECKOUT_SHIPPING: '/despacho',
  CHECKOUT_PURCHASE: '/compra',
  CHECKOUT_ORDER_SUCCESS: '/compraexitosa',
  SUCCESS: '/success',
  PRODUCT: '/producto',
  SEARCH: '/busqueda',
  STATIC_PAGES: '/paginas',
  SUGGESTED_PRODUCTS_PAGE: '/productosugeridos',

  // STATIC PAGES
  PROMOTIONS_PAGE: '/pagina/promociones',

  // MY ORDERS
  MY_ORDERS: '/mispedidos',

  // MY SESSION
  MY_SESSION: '/miperfil',

  // WHATSAPP EXTERNAL
  WHATSAPP: WHATSAPP_CUSTOMER_SERVICE_LINK,

  // TRAINING EXTERNAL
  TRAINING: 'https://crecemosjuntos.com.pe/',
  TECH_ASSISTANCE: 'https://crecemosjuntos.com.pe/asesoria-tecnica/',
};

export const PATHS_WITH_INSTANT_SCROLL = [`${Path.CHECKOUT}${Path.CHECKOUT_PURCHASE}`];
