import { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { SnackbarGlobal } from '@insuma/mpp-ui/components/snackbar';
import { ThemeProvider } from '@insuma/mpp-ui/themes';
import { PersistGate } from 'redux-persist/integration/react';
import { insumaTheme } from 'core/constants/themes.constants';
import { MonitoringProvider } from 'core/contexts/monitoring.context';
import { ScreenProvider } from 'core/contexts/screen.context';
import monitoringInstance from 'shared/utils/monitoring-provider.utils';
import store, { persistor } from '../core/store';
import msalPublicClientApplication, { CustomNavigationClient } from '../shared/utils/msal.utils';
import { RollbackInternalError } from './errors/rollback-internal-error';
import { MainRoutes } from './main.routes';

const LazyAnalytics = lazy(() => import('shared/components/analytics'));

const AppContent = () => {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  msalPublicClientApplication.setNavigationClient(navigationClient);

  return (
    <MonitoringProvider provider={monitoringInstance}>
      <MsalProvider instance={msalPublicClientApplication}>
        <ThemeProvider theme={insumaTheme}>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ScreenProvider>
                <MainRoutes />
              </ScreenProvider>
              <Suspense fallback={null}>
                <LazyAnalytics />
              </Suspense>
            </PersistGate>
          </ReduxProvider>
          {/* <WhatsappButton /> */}
          <SnackbarGlobal />
        </ThemeProvider>
      </MsalProvider>
    </MonitoringProvider>
  );
};

const App = () => (
  <ErrorBoundary FallbackComponent={RollbackInternalError}>
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  </ErrorBoundary>
);

export default App;
