import { MarkupFormatter } from 'shared/components/markup-formatter';

import './message-box.scss';

export enum EMessageBoxType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export interface IMessageBoxProps {
  isFullWidth?: boolean;
  iconName?: string;
  image?: string;
  message: string;
  type?: EMessageBoxType;
}

export const MessageBox = ({
  isFullWidth,
  image,
  iconName,
  message,
  type = EMessageBoxType.SUCCESS,
}: IMessageBoxProps) => (
  <div className={`message-checkout message-checkout--${type} ${isFullWidth ? 'message-checkout--full-width' : ''} `}>
    <div className="message-checkout__emojin">
      <img data-testid="message-box-image" src={image} alt={iconName} />
    </div>
    <p className="message-checkout__subtitle">
      <MarkupFormatter>{message}</MarkupFormatter>
    </p>
  </div>
);
