import { useBannerMessage } from 'screens/suggested-products/hooks/use-banner-message';
import { DebtBanner } from 'shared/components/debt-banner';
import { FixedBanner } from 'shared/components/fixed-banner';
import { BannerMultipleAddress } from 'shared/components/mutiple-address';
import { StickyBanner } from 'shared/components/sticky-banner';

export const TopbarBanner = () => {
  const { messageBanner } = useBannerMessage();

  return (
    <StickyBanner>
      <BannerMultipleAddress />
      <DebtBanner origin="Home" />
      {messageBanner && <FixedBanner title={messageBanner} />}
    </StickyBanner>
  );
};
