import { BaseModal } from 'shared/components/modals/base-modal';

interface IDesktopSelectorMultipleAddressProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export const DesktopSelectorMultipleAddress = ({ children, isOpen, onClose }: IDesktopSelectorMultipleAddressProps) => (
  <BaseModal visible={isOpen} close={onClose}>
    {children}
  </BaseModal>
);
