import { useScreen } from 'core/contexts/screen.context';
import { useAddressModal } from 'core/hooks/address/use-address-selector.hooks';
import { useSelectedAddress } from 'core/hooks/address/use-selected-address.hooks';
import { AddressSelector, DesktopSelectorMultipleAddress, MobileSelectorMultipleAddress } from './components';

export const AddressSelectorModal = () => {
  const { isSelectorAddressOpen, setSelectorAddressOpen, addresses } = useAddressModal();
  const { setSelectedAddress: updateAddress, selectedAddress } = useSelectedAddress();
  const { isMobile } = useScreen();
  const AdressSelectorContainer = isMobile ? MobileSelectorMultipleAddress : DesktopSelectorMultipleAddress;

  const addressSelectorModalProps = {
    isOpen: isSelectorAddressOpen,
    onClose: () => setSelectorAddressOpen(false),
  };

  if (addresses?.length < 2) return null;

  return (
    <AdressSelectorContainer {...addressSelectorModalProps}>
      <AddressSelector
        selectedAddress={selectedAddress}
        addresses={addresses}
        onChangeAddress={updateAddress}
        setSelectorAddressOpen={setSelectorAddressOpen}
      />
    </AdressSelectorContainer>
  );
};
