import { DEFAULT_CURRENCY } from 'core/constants/currency.constants';
import { DEBOUNCE_TIME_TO_VIEW_PRODUCTS } from 'core/constants/products.constants';
import { EProductOrigin } from 'core/model/enums/gtm.enum';
import { IProduct } from 'core/model/interfaces/product.interface';
import { pushObjectDataLayer } from './gtm.events';

interface ITriggerViewProductList {
  products: Array<IProduct>;
  startIndex?: number;
  listName?: string;
  origin: EProductOrigin;
}

interface ITriggerViewProduct extends Omit<ITriggerViewProductList, 'products'> {
  product: IProduct;
}

let productsQueue: Array<IProduct> = [];
let timeout: NodeJS.Timeout;

const processProductsInQueue = (payload: ITriggerViewProductList) => {
  clearTimeout(timeout);
  if (productsQueue.length > 0) {
    timeout = setTimeout(() => {
      triggerViewProductListEvent(payload);
      productsQueue = [];
    }, DEBOUNCE_TIME_TO_VIEW_PRODUCTS);
  }
};

export const queueProductViewEvent = ({ product, origin, listName, startIndex }: ITriggerViewProduct) => {
  productsQueue.push(product);
  processProductsInQueue({ products: productsQueue, origin, listName, startIndex });
};

export const triggerViewProductListEvent = ({
  products,
  listName = 'null',
  origin,
  startIndex = 0,
}: ITriggerViewProductList) => {
  const items = products.map((p, index) => ({
    item_name: p.name,
    item_id: p.sku,
    price: p.price,
    item_brand: p.brand,
    item_category: p.categoryName,
    item_category2: p.subcategoryName,
    item_list_id: listName,
    item_list_name: listName,
    procedencia: p.providerDisplayName,
    dex: p.distributorName,
    quantity: 1,
    product_ean: p.eanCode || 'no-existe',
    product_sku: p.sku,
    metadata: origin,
    aplica_descuento: p.hasDiscount ? 'Si' : 'No',
    presentacion: p.presentation,
    ...(startIndex && {
      index: startIndex + index + 1,
    }),
  }));
  const data = {
    event: 'view_item_list',
    ecommerce: {
      currency: DEFAULT_CURRENCY.id,
      items,
    },
  };
  pushObjectDataLayer(data, triggerViewProductListEvent.name);
};
