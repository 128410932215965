import { Icon } from '@insuma/mpp-ui/components/icon';
import { TSeeMoreCardVariant } from './see-more-card.types';

import './see-more-card.scss';

interface ISeeMoreCardProps {
  handleSeeAll: () => void;
  title: string;
  variant: TSeeMoreCardVariant;
}

export const SeeMoreCard = ({ handleSeeAll, title, variant = 'default' }: ISeeMoreCardProps) => {
  const isSmall = variant === 'simplified';

  return (
    <article className={`mpp-see-more-card ${isSmall ? 'mpp-see-more-card--small' : ''}`}>
      <div className="mpp-see-more-card__content">
        <h4 className="mpp-see-more-card__title">{title}</h4>
        <button onClick={handleSeeAll} className="mpp-see-more-card__button" type="button">
          Ver más <Icon name="caret-right" size="sm" />
        </button>
      </div>
    </article>
  );
};
