import { lazy } from 'react';

// TODO: investigate how to use lazy without default
const LazySupplyingShoppingCartDrawer = lazy(() => import('./shopping-cart-drawer'));
const LazyMyOrders = lazy(() => import('./my-orders'));
const LazyMySession = lazy(() => import('./my-profile'));
const LazyStaticPages = lazy(() => import('./static-page'));
const LazySuggestedProductsPages = lazy(() => import('./suggested-products'));
const LazyNotFound = lazy(() => import('./errors/not-found'));
const LazyLogout = lazy(() => import('./authentication/logout'));
const LazyCheckout = lazy(() => import('./checkout'));
const LazyHome = lazy(() => import('./home'));
const LazySearchResults = lazy(() => import('./search-results'));
const LazyProductDetail = lazy(() => import('./product-detail'));
const LazyCategoryCatalog = lazy(() => import('./category-catalog'));
const LazySubcategoryCatalog = lazy(() => import('./subcategory-catalog'));
const LazyMenuEntries = lazy(() => import('shared/components/navigation-ui/menu-entries'));

export {
  LazyCategoryCatalog,
  LazyCheckout,
  LazyHome,
  LazyLogout,
  LazyMenuEntries,
  LazyMyOrders,
  LazyMySession,
  LazyNotFound,
  LazyProductDetail,
  LazySearchResults,
  LazyStaticPages,
  LazySubcategoryCatalog,
  LazySuggestedProductsPages,
  LazySupplyingShoppingCartDrawer,
};
