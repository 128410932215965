import { Skeleton } from '@insuma/mpp-ui/components/skeleton';
import { Arrow } from '../arrow';
import { Dots } from '../dots';

import '../../slider.scss';

export const SliderLoading = () => (
  <div className="slider">
    <div className="banners">
      <Skeleton className="slider__slide-loading" />
      <Arrow direction="left" />
      <Arrow direction="right" />
    </div>
    <Dots slides={[{ imageUrl: '', redirectionUrl: '' }]} activeSlide={0} />
  </div>
);
