import { ReactElement } from 'react';

import './main-container.scss';

interface IMainContainerContentProps {
  children?: ReactElement | Array<ReactElement>;
  classNames?: string;
  sectionClassName?: string;
}

export const MainContainer = ({ children, classNames, sectionClassName }: IMainContainerContentProps) => (
  <div className={`main-container ${classNames}`}>
    <main className="main-container__main">
      <section className={sectionClassName || 'main-container__section'}>{children}</section>
    </main>
  </div>
);
