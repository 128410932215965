import { memo } from 'react';
import { InView } from 'react-intersection-observer';
import type { TProductCardVariant } from '@insuma/mpp-ui/components/product-card';
import { EProductOrigin } from 'core/model/enums/gtm.enum';
import { IProduct } from 'core/model/interfaces/product.interface';
import { isObjectSimpleEqual } from 'shared/utils/object.utils';
import { SeeMoreButton } from '../../see-more-button';
import { CarouselCardPoductsBody } from './components/carousel-card-products-body';

import './carousel-card-products-generic.scss';

interface ICarouselCardPoductsGenericProps {
  listName?: string;
  origin: EProductOrigin;
  title: string;
  gtmRegisterListProducts: (products: Array<IProduct>) => void;
  handleSeeAll: () => void;
  variant?: TProductCardVariant;
  products: Array<IProduct>;
  totalItems: number;
  showSeeMore: boolean;
  shouldHideHeader?: boolean;
  suggestedProductType?: string;
}

export const CarouselCardPoductsGeneric = memo(
  ({
    products,
    listName = 'null',
    origin,
    title,
    handleSeeAll,
    gtmRegisterListProducts,
    suggestedProductType,
    variant = 'default',
    totalItems,
    showSeeMore,
    shouldHideHeader = false,
  }: ICarouselCardPoductsGenericProps) => {
    const isSmall = variant === 'simplified';

    return (
      <div>
        {products?.length !== 0 && (
          <div className="carousel-card-products-generic">
            {!shouldHideHeader && (
              <div
                className={`carousel-card-products-generic__header ${
                  isSmall ? 'carousel-card-products-generic__header--small' : ''
                }`}
              >
                <div className="carousel-card-products-generic__header-content">
                  <h3
                    className={`carousel-card-products-generic__title carousel-card-products-generic__title--${
                      isSmall ? 'small' : 'regular'
                    }`}
                  >
                    {title}
                  </h3>
                  <div className="carousel-card-products-generic__amount">{totalItems} Productos</div>
                </div>
                {showSeeMore && <SeeMoreButton onClick={handleSeeAll} />}
              </div>
            )}
            <InView
              as="div"
              triggerOnce
              onChange={inView => inView && gtmRegisterListProducts(products)}
              className="carousel-card-products-generic__body"
            >
              <CarouselCardPoductsBody
                handleSeeAll={handleSeeAll}
                products={products}
                title={title}
                origin={origin}
                listName={listName}
                variant={variant}
                seeMore={showSeeMore}
                suggestedProductType={suggestedProductType}
              />
            </InView>
          </div>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => isObjectSimpleEqual(prevProps, nextProps),
);
