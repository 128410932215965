import { getShortcutsSelector } from 'core/store/customization/selectors';
import { useAppSelector } from 'core/store/store.utils';

import './shortcuts-banner.scss';

export const ShortcutsBannersList = () => {
  const { shortcuts: items } = useAppSelector(getShortcutsSelector);

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={`shortcuts-banner ${items.length === 4 ? 'shortcuts-banner--four-items' : ''}`}>
      {items
        .filter(shortcut => shortcut.images.length !== 0)
        .map(shortcut => (
          <div
            key={shortcut.id}
            className="shortcuts-banner__item"
            style={{
              backgroundImage: `linear-gradient(#201e1e80, #201e1e80), url(${shortcut.images[0].url})`,
            }}
          >
            <p className="shortcuts-banner__item-title">{shortcut.title}</p>
            <a className="shortcuts-banner__item-button" href={shortcut.urlRedirect} target="_blank" rel="noreferrer">
              {shortcut.buttonText}
            </a>
          </div>
        ))}
    </div>
  );
};
