import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import './base-modal.scss';

export interface IBaseModalProps {
  visible: boolean;
  children?: React.ReactNode;
  close?: () => void;
  disableBodyOverflow?: boolean;
  className?: string;
  noPortal?: boolean;
}

const modalElement = document.getElementById('modal-root');

export const BaseModal = ({
  visible,
  children,
  close = () => {},
  disableBodyOverflow = true,
  className = '',
  noPortal = false,
}: IBaseModalProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [hideBckDrop, sethideBckDrop] = useState(true);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (!disableBodyOverflow) return () => body.removeAttribute('style');
      if (visible) body.style.overflow = 'hidden';
      else body.removeAttribute('style');
      return () => body.removeAttribute('style');
    }
  }, [visible, disableBodyOverflow]);

  const escapeListener = useCallback(
    e => {
      if (e.key === 'Escape' && visible) close();
    },
    [close, visible],
  );

  const clickListener = useCallback(
    e => {
      if (ref.current?.contains(e.target) && visible) close();
    },
    [close, visible],
  );

  const constructInternalModules = () => {
    if (!hideBckDrop)
      return (
        <div className="kt-modal">
          <div ref={ref} className={`kt-modal__backdrop ${!visible && 'hide'}`} />
          <div className={`${className} kt-modal__container ${visible && 'kt-modal__container--show'}`}>{children}</div>
        </div>
      );
    return null;
  };

  useEffect(() => {
    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', escapeListener);
    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  }, [clickListener, escapeListener]);

  useEffect(() => {
    if (visible) sethideBckDrop(false);
    else {
      const timer = setTimeout(() => {
        sethideBckDrop(true);
      }, 400);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [visible]);

  if (!modalElement) {
    return null;
  }

  return !noPortal ? createPortal(constructInternalModules(), modalElement) : constructInternalModules();
};
