import { Skeleton } from '@insuma/mpp-ui/components/skeleton';

import './search-product-preview-skeleton.scss';

export const SearchProductPreviewSkeleton = () => (
  <div className="serach-product-preview-skeleton">
    <Skeleton className="serach-product-preview-skeleton__image" />
    <div className="serach-product-preview-skeleton__column">
      <div className="serach-product-preview-skeleton__name">
        <Skeleton />
      </div>
      <div className="serach-product-preview-skeleton__description">
        <Skeleton />
      </div>
      <div className="serach-product-preview-skeleton__price">
        <Skeleton className="serach-product-preview-skeleton__skl" />
      </div>
    </div>
  </div>
);
