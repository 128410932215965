import { ECurrencySymbols } from 'core/model/enums/currency.enum';
import { ICustomer } from 'core/model/interfaces/customer.interface';
import {
  IInsiderCart,
  IInsiderLineItem,
  IInsiderProduct,
  IInsiderTransaction,
  IInsiderUser,
} from 'core/model/interfaces/insider.interface';
import { IOrderCreated, IOrderDetailItem } from 'core/model/interfaces/order.interface';
import { IPaymentType } from 'core/model/interfaces/payment-type.interface';
import { ICartProduct, IDetailProduct, IProduct } from 'core/model/interfaces/product.interface';

export const cutomerToInsiderUser = (customer: ICustomer): IInsiderUser => {
  const { addresses, basicData: user, priceGroup, id, priceList, dex, apudex } = customer;
  const primaryPhone = user.phones?.find(u => u.isMain)?.number;
  const totalPaymentMethods: Array<IPaymentType> = [...(dex?.paymentMethods || []), ...(apudex?.paymentMethods || [])];

  return {
    email: user.email ?? '',
    uuid: user.document?.number,
    name: user.name,
    phone_number: `+51${primaryPhone}`,
    surname: user.firstLastName,
    gdpr_optin: true,
    email_optin: true,
    sms_optin: true,
    whatsapp_optin: true,
    custom: {
      customerid: id,
      workArea: priceGroup,
      subWorkArea: priceList,
      segment: dex?.segment ?? '',
      typeDocument: user.document?.type,
      numberDocument: user.document?.number,
      secondLastName: user.secondLastName,
      businessid: addresses?.map(bi => bi.id)?.join(),
      address: addresses?.map(bi => bi.address)?.join(),
      district: addresses?.map(bi => bi.district)?.join(),
      province: addresses?.map(bi => bi.province)?.join(),
      department: addresses?.map(bi => bi.department)?.join(),
      ubigeoCode: addresses?.map(bi => bi.ubigeoCode)?.join(),
      sellinsid: totalPaymentMethods?.map(si => si.id)?.join(),
      sellinspaymentType: totalPaymentMethods?.map(si => si.description)?.join(),
      documenttype: user.document?.type,
      documentnumber: user.document?.number,
    },
  };
};

export const productListToInsiderProductList = (products: Array<IProduct>): Array<IInsiderProduct> =>
  products.map(productToInsiderProduct);

const productToInsiderProduct = (product: IProduct): IInsiderProduct => ({
  id: product.sku,
  name: product.name,
  taxonomy: [product.categoryId?.toString(), product.subcategoryId?.toString()],
  currency: product.currency.symbol,
  unit_price: product.originalPrice,
  unit_sale_price: product.price,
  url: `/producto/${product.sku}`,
  stock: product.stock,
  product_image_url: product.image,
  custom: {
    brand: product.brand,
    description: product.description,
    eanCode: product.eanCode,
    maximumSaleQuantity: product.maximumSaleQuantity,
    minimumSaleQuantity: product.minimumSaleQuantity,
    presentation: product.presentation,
    hasDiscount: product.hasDiscount,
    providerDisplayName: product.providerDisplayName,
    sourceId: product.sourceId,
    subcategoryName: product.subcategoryName,
    categoryName: product.categoryName,
    taxRate: product.taxRate,
    selectedUnitMeasureCode: product.selectedUnitMeasure.code,
  },
});

export const productDetailToInsiderProduct = (product: IDetailProduct): IInsiderProduct => ({
  id: product.sku,
  name: product.name,
  taxonomy: [product.categoryId?.toString(), product.subcategoryId?.toString()],
  currency: product.currency.symbol,
  unit_price: product.originalPrice,
  unit_sale_price: product.price,
  url: `/producto/${product.sku}`,
  stock: product.stock,
  product_image_url: product.image,
  custom: {
    brand: product.brand,
    description: product.description,
    eanCode: product.eanCode,
    maximumSaleQuantity: product.maximumSaleQuantity,
    minimumSaleQuantity: product.minimumSaleQuantity,
    presentation: product.presentation,
    hasDiscount: product.hasDiscount,
    providerDisplayName: product.providerDisplayName,
    sourceId: product.sourceId,
    subcategoryName: product.subcategoryName,
    categoryName: product.categoryName,
    taxRate: product.taxRate,
    selectedUnitMeasureCode: product.selectedUnitMeasure.code,
    unitMeasuresCode: product.unitMeasures.map(uM => uM.code)?.join(),
    distributorCode: product.distributor.id,
  },
});

export const cartToInsiderCart = (products: Array<ICartProduct>, currency: string, total: number): IInsiderCart => ({
  currency,
  total,
  line_items: products.map(p => ({
    product: productToInsiderProduct(p),
    quantity: p.quantity,
    subtotal: p.amounts.price,
  })),
});

const mapProductsToLineItemsInsider = (product: IOrderDetailItem, orderId: number): IInsiderLineItem => ({
  product: {
    id: product.sku,
    name: product.name,
    taxonomy: [],
    currency: product.currency.id,
    unit_price: product.price,
    unit_sale_price: product.price,
    url: `/producto/${product.sku}`,
    product_image_url: product.image,
    custom: {
      orderId,
      presentation: product.presentation,
      hasDiscount: product.hasDiscount,
      providerDisplayName: product.displayName,
    },
  },
  quantity: product.quantity,
  subtotal: product.price,
});

export const orderDetailToInsiderTransaction = (orders: Array<IOrderCreated>): IInsiderTransaction => {
  const order_id = orders[0].orderId?.toString();
  const currency = ECurrencySymbols.PEN;
  const total = orders.reduce((n, { totalAmount }) => n + totalAmount, 0);
  let lineItemsByOrders: Array<IInsiderLineItem> = [];

  orders.forEach(order => {
    const lineItemsByOrder = order.products.map(product => mapProductsToLineItemsInsider(product, order.orderId));
    lineItemsByOrders = lineItemsByOrders.concat(lineItemsByOrder);
  });

  const orderDetail = {
    order_id,
    currency,
    total,
    line_items: lineItemsByOrders,
  };

  return orderDetail;
};
