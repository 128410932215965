import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Path } from 'core/constants/path.constants';
import { DEBOUNCE_TIME_TO_FETCH_SUGGESTION_PRODUCTS } from 'core/constants/suggested-products.constants';
import { EProductOrigin } from 'core/model/enums/gtm.enum';
import { IMetadata, IProduct, IUnitMeasure } from 'core/model/interfaces/product.interface';
import {
  buildOptimisticCartAction,
  changeProductAction,
  changeProductActionFromCart,
  optimisticRemoveProductAction,
  removeProductAction,
  removeProductFromCartAction,
} from 'core/store/cart';
import { IChangeProductActionParams } from 'core/store/cart/types';
import { resetIdleShipping } from 'core/store/checkout';
import { toogleCart } from 'core/store/layout';
import { useAppDispatch } from 'core/store/store.utils';
import { dispatchGetSuggestedProductsForAllLocations } from 'core/store/suggested-products/utils';
import gtmEvents from 'shared/utils/gtm-events';

export interface IUseProductActionsParams {
  listName?: string;
  origin: EProductOrigin;
  loading?: boolean;
}

export interface IOnChangeCounterQuantity {
  item: IProduct;
  currentQuantity: number;
  newQuantity: number;
  unitMeasure: IUnitMeasure;
  index?: number;
  metadata?: IMetadata;
  suggestedProductType?: string;
}

interface IOnChangeQuantity extends Omit<IOnChangeCounterQuantity, 'index' | 'metadata'> {
  metadata: IMetadata;
}

export interface IOnDeleteProductParams extends Pick<IOnChangeCounterQuantity, 'item'> {
  metadata: IMetadata;
  quantityBeforeRemoval: number;
}

export const useProductActions = ({ listName = 'null', origin, loading = false }: IUseProductActionsParams) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const shouldRefreshCheckoutDetails = pathname.includes(Path.CHECKOUT);
  const fetchSuggestedProductsTimeoutRef = useRef<NodeJS.Timeout>();

  // TODO 💣 type changeProductActionRef
  const changeProductActionRef = useRef<any>();

  const onDetailProduct = (product: IProduct) => {
    gtmEvents.triggerSelectedProduct({ product, listName, origin });
    if (!loading) {
      dispatch(toogleCart(false));
      navigate(`${Path.PRODUCT}/${product?.sku}`);
    }
  };

  const onDeleteProduct = async (params: IOnDeleteProductParams) => {
    const { metadata, item: product, quantityBeforeRemoval } = params;
    const inCart = origin === EProductOrigin.SHOPPING_CART || origin === EProductOrigin.SUGGESTED_PRODUCTS_CART;

    if (inCart) await dispatch(removeProductFromCartAction({ ...product, shouldRefreshCheckoutDetails }));
    else {
      dispatch(optimisticRemoveProductAction(product));
      dispatch(removeProductAction(product.sku));
    }
    gtmEvents.removeProductFromShoppingCartEvent({
      product,
      quantityBeforeRemoval,
      metadata: { ...metadata, origin },
    });
  };

  const onChangeProduct = ({
    item,
    currentQuantity,
    newQuantity,
    unitMeasure,
    metadata,
    suggestedProductType,
  }: IOnChangeQuantity) => {
    if (!item) return;
    const hasReachedMaxStock = newQuantity === item.stock;
    const isDecrementingProducts = newQuantity < currentQuantity;
    const { sku } = item;
    if (isDecrementingProducts) {
      gtmEvents.removeProductFromShoppingCartEvent({
        product: item,
        quantityBeforeRemoval: currentQuantity - newQuantity,
        metadata,
      });
    } else {
      gtmEvents.addProductToShoppingCartEvent({
        product: item,
        quantity: newQuantity - currentQuantity,
        metadata,
        hasReachedMaxStock,
      });
    }
    const inCart = origin === EProductOrigin.SHOPPING_CART || origin === EProductOrigin.SUGGESTED_PRODUCTS_CART;

    const changeProductActionParams: IChangeProductActionParams = {
      product: item,
      sku,
      quantity: newQuantity,
      listName,
      origin,
      unitMeasure,
      metadata,
      suggestedProductType,
    };

    if (inCart)
      dispatch(
        changeProductActionFromCart({
          ...changeProductActionParams,
          shouldRefreshCheckoutDetails,
        }),
      );
    else {
      dispatch(buildOptimisticCartAction(changeProductActionParams));
      dispatch(resetIdleShipping());

      changeProductActionRef.current = dispatch(changeProductAction(changeProductActionParams));

      if (fetchSuggestedProductsTimeoutRef.current) clearTimeout(fetchSuggestedProductsTimeoutRef.current);
      fetchSuggestedProductsTimeoutRef.current = setTimeout(() => {
        dispatchGetSuggestedProductsForAllLocations(dispatch);
      }, DEBOUNCE_TIME_TO_FETCH_SUGGESTION_PRODUCTS);
    }
  };

  const onChangeCounterQuantity = ({
    item,
    currentQuantity,
    newQuantity,
    unitMeasure,
    index = 0,
    metadata,
    suggestedProductType,
  }: IOnChangeCounterQuantity) => {
    const computedMetadata = metadata ?? { origin, index: index + 1, itemListName: listName };
    if (newQuantity <= 0 || newQuantity < item.minimumSaleQuantity)
      onDeleteProduct({ item, quantityBeforeRemoval: currentQuantity, metadata: computedMetadata });
    else {
      onChangeProduct({
        item,
        currentQuantity,
        newQuantity,
        unitMeasure,
        suggestedProductType,
        metadata: computedMetadata,
      });
    }
  };

  const onAbort = (sku: string, unitMeasureId: string) => {
    if (
      changeProductActionRef.current?.arg?.sku === sku &&
      changeProductActionRef.current?.arg?.unitMeasure.code === unitMeasureId
    )
      changeProductActionRef.current?.abort();
  };

  return { onAbort, onChangeCounterQuantity, onDetailProduct, onDeleteProduct };
};
