import { memo } from 'react';

interface IDotProps {
  active?: boolean;
}

interface IDotsProps {
  slides: Array<{
    imageUrl: string;
    redirectionUrl: string | null;
  }>;
  activeSlide: number;
}

const Dot = ({ active = false }: IDotProps) => <div className={`dot-${active ? 'on' : 'off'}`} />;

const MemoDot = memo(Dot);

export const Dots = ({ slides, activeSlide }: IDotsProps) => (
  <div className="dots">
    {slides.map((slide, i) => (
      <MemoDot key={slide.imageUrl} active={activeSlide === i} />
    ))}
  </div>
);
