import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@alicorpdigital/dali-components-react';
import { Path } from 'core/constants/path.constants';
import { useScreen } from 'core/contexts/screen.context';
import { IProduct } from 'core/model/interfaces/product.interface';
import { apiService } from 'core/services';
import { checkoutCustomerSelector } from 'core/store/checkout/selectors';
import { useAppSelector } from 'core/store/store.utils';
import { getProductCommercialName } from 'shared/utils/products.utils';
import { SearchProductPreview, SearchProductPreviewSkeleton } from '../search-product-preview';

import './search-preview-results.scss';

interface ISearchPreviewResultsProps {
  inputValue: string;
  searchValue: string;
  isFocused: boolean;
}

export const SearchPreviewResults = ({ inputValue, isFocused, searchValue }: ISearchPreviewResultsProps) => {
  const customerId = useAppSelector(checkoutCustomerSelector);
  const { width } = useScreen();
  const navigate = useNavigate();
  const [previewItems, setPreviewItems] = useState<Array<IProduct>>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isSearching, setIsSearching] = useState(false);

  const perPage = width <= 880 ? 3 : 5;
  const skeletonItems = [...new Array(perPage)].map((_, index) => <SearchProductPreviewSkeleton key={index} />);

  useEffect(() => {
    if (inputValue.length < 3) return;

    setIsSearching(true);
    if (inputValue === searchValue) {
      setIsSearching(false);
    }
  }, [inputValue, searchValue]);

  useEffect(() => {
    if (searchValue.length >= 3 && customerId) {
      setIsSearching(true);
      apiService.catalog
        .getListProducts(
          {
            itemsPerPage: perPage,
            q: searchValue,
          },
          customerId,
        )
        .then(response => {
          setPreviewItems(response.products.slice(0, perPage));
          setTotalItems(response.pagination.totalItems);
          setIsSearching(false);
        });
    }
  }, [searchValue, perPage, customerId]);

  useEffect(() => {
    const htmlElement = document.documentElement;
    if (isFocused) {
      htmlElement.classList.add('dl-sidebar-hidden-scroll-y');
    } else {
      htmlElement.classList.remove('dl-sidebar-hidden-scroll-y');
    }
  }, [isFocused]);

  const productToSearchProductPreviewProps = (product: IProduct) => ({
    productId: product.sku,
    displayName: getProductCommercialName(product.commercialName, product.name),
    originalPrice: product.originalPrice,
    hasDiscount: product.hasDiscount,
    image: product.image,
    presentation: product.presentation,
    price: product.price,
    currency: product.currency,
    onDetailProduct: () => {
      navigate(`${Path.PRODUCT}/${product.sku}`);
    },
  });

  return isFocused ? (
    <div className="search-preview-results">
      {inputValue.length >= 3 && (
        <div data-testid="search-preview-container" className="search-preview-results__container">
          {isSearching ? (
            <div>{skeletonItems}</div>
          ) : (
            <div>
              {previewItems.map(item => (
                <SearchProductPreview key={item.sku} {...productToSearchProductPreviewProps(item)} />
              ))}
              {totalItems === 0 && (
                <div className="search-preview-results__empty">
                  Lo sentimos, no encontramos productos con la palabra "{searchValue}"
                </div>
              )}
              {totalItems > 0 && (
                <div className="search-preview-results__actions">
                  <Button variant="tertiary" size="xs" onClick={() => navigate(`${Path.SEARCH}?q=${searchValue}`)}>
                    Ver todos los resultados ({totalItems})
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <div data-testid="search-preview-overlay" className="search-preview-results__overlay" />
    </div>
  ) : null;
};
