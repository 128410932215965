import { type MouseEventHandler, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@insuma/mpp-ui/components/icon';
import { SkeletonPlaceHolder } from '@insuma/mpp-ui/components/skeleton';
import { ImageRoutes } from 'core/constants/image-routes';
import { Path } from 'core/constants/path.constants';
import { authLoadingSelector } from 'core/store/auth/selectors';
import { setSearchQuery } from 'core/store/layout';
import { searchLayoutSelector } from 'core/store/layout/selectors';
import { useAppDispatch, useAppSelector } from 'core/store/store.utils';
import useQuery from 'shared/hooks/use-query-params';
import useWindowSize from 'shared/hooks/use-window-size';
import { HeaderWrapper } from '../header-wrapper';
import { CustomSearchInput } from './components/custom-search-input';
import { HeaderCartButton } from './components/header-cart-button';
import { HeaderCartSkeletonButton } from './components/header-cart-button/header-cart-skeleton-button';

import './main-header.scss';

export interface IMainHeaderProps {
  onToggleMenu: MouseEventHandler<HTMLButtonElement>;
  onToggleCart: MouseEventHandler<HTMLButtonElement>;
}

export const MainHeader = ({ onToggleMenu, onToggleCart }: IMainHeaderProps) => {
  const navigate = useNavigate();
  const queryInstance = useQuery();
  const query = queryInstance.get('q');
  const [searchValue, setSearchValue] = useState(query || '');
  const [focusInputSearch, setFocusInputSearch] = useState(false);

  const isLoadingProfile = useAppSelector(authLoadingSelector);

  const dispatch = useAppDispatch();

  const { pathname: path } = useLocation();
  const search = useAppSelector(searchLayoutSelector);

  const { width } = useWindowSize();
  const isMobile = width <= 880;

  const onSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const onCancel = () => {
    setSearchValue('');
  };

  useEffect(() => {
    if (path !== Path.SEARCH) setSearchValue('');
  }, [path, setSearchValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchValue.length >= 3 && search !== searchValue) {
        dispatch(setSearchQuery(searchValue));
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [searchValue, search, dispatch]);

  const handleClickLogo = (): void => {
    if (path === Path.HOME) {
      document.location.reload();
      return;
    }
    navigate(Path.HOME);
  };

  const validMobileSearch = (): boolean => !isMobile || !(focusInputSearch || searchValue.length > 0);

  return (
    <HeaderWrapper>
      {validMobileSearch() && (
        <div className="main-header__icons">
          <button type="button" className="main-header__burguer" aria-label="menu lateral" onClick={onToggleMenu}>
            <Icon name="hamburger" />
          </button>
          <button type="button" className="main-header__logo" onClick={handleClickLogo}>
            <img
              className="main-header__image"
              src={ImageRoutes.LOGO_MAIN_HEADER}
              alt="Logo Insuma"
              width="100"
              height="32"
            />
          </button>
        </div>
      )}
      <CustomSearchInput
        className={`main-header__search-container ${
          isMobile && (focusInputSearch || searchValue.length > 0) ? 'main-header__search-container--fullwidth' : ''
        }`}
        placeholder={validMobileSearch() ? '' : 'Buscar productos'}
        value={searchValue}
        setValue={setSearchValue}
        onChange={(e: any): void => onSearchChange(e.target.value)}
        onCancel={(): void => onCancel()}
        isFocused={focusInputSearch}
        isFocus={focus => setFocusInputSearch(focus)}
        searchValue={search}
      />

      <SkeletonPlaceHolder loading={isLoadingProfile} skeleton={<HeaderCartSkeletonButton />}>
        <HeaderCartButton onToggleCart={onToggleCart} />
      </SkeletonPlaceHolder>
    </HeaderWrapper>
  );
};
