import { GenericAbortSignal } from 'axios';
import { ICatalogueProducts } from 'core/model/interfaces/catalogues.interface';
import { IPaginatedProducts } from 'core/model/interfaces/product.interface';
import { catalogsData } from './catalogs.data';
import { IGetCatalogProductsParams, IGetListProductsParams, IGetProductDetailParams } from './catalogs.dto';
import {
  catalogDetailResponseToProduct,
  listProductsToPaginatedProducts,
  navigationCatalogToCatalogueProducts,
} from './catalogs.mappers';

export const catalogsService = {
  getListProducts: async (
    params: IGetListProductsParams,
    customerId: number,
    signal?: GenericAbortSignal,
  ): Promise<IPaginatedProducts> => {
    const listProducts = await catalogsData.getListProducts(params, customerId, signal);
    return listProductsToPaginatedProducts(listProducts);
  },

  getProductDetail: async (params: IGetProductDetailParams, customerId: number) => {
    const { product } = await catalogsData.getProductDetail(params, customerId);
    return catalogDetailResponseToProduct(product);
  },

  getCatalogProducts: async (
    params: IGetCatalogProductsParams,
    customerId: number,
    parentId?: string,
  ): Promise<Array<ICatalogueProducts>> => {
    const { navigation } = await catalogsData.getCatalogProducts(params, customerId);

    const treeItemType = parentId ? 'subcategory' : 'category';

    return navigation.map(item => navigationCatalogToCatalogueProducts(item, treeItemType));
  },
};
