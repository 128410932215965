import { Skeleton } from '@insuma/mpp-ui/components/skeleton';

import './product-cards-skeleton.scss';

export const ProductCardsSkeleton = () => (
  <div className="product-cards-skeleton">
    <Skeleton style={{ height: '176px' }} />
    <Skeleton style={{ height: '14px' }} />
    <Skeleton style={{ width: '40%', height: '12px' }} />
    <Skeleton style={{ width: '50%', height: '20px' }} />
  </div>
);
