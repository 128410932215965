import { ImageRoutes } from 'core/constants/image-routes';
import { ICatalogueProducts } from 'core/model/interfaces/catalogues.interface';
import { IDetailProduct, IPaginatedProducts, IProduct } from 'core/model/interfaces/product.interface';
import { IGetListProductsDTO, INavigationCatalogDTO, IProductDTO } from './catalogs.dto';

export const productDTOToIProduct = (product: IProductDTO): IProduct => {
  const {
    brand,
    name,
    currency,
    description,
    image,
    sku,
    sourceId,
    stock,
    taxRate,
    unitMeasure,
    eanCode,
    externalCategory,
    externalFamily,
    externalProvider,
    commercialName,
    externalDistributor,
  } = product;

  // TODO: Considerar el caso cuando defaultUnitMeasure sea undefined
  const activeUnitMeasures = unitMeasure.filter(um => um.isActive === true);
  if (activeUnitMeasures.length < 1) throw new Error(`There are no active unit measures for sku: ${product.sku}`);
  const defaultUnitMeasure = activeUnitMeasures.find(um => um.default === true) || activeUnitMeasures[0];

  return {
    image: image || ImageRoutes.CATALOGUE_DEFAULT_PRODUCT,
    brand,
    categoryId: Number(externalCategory.id),
    currency,
    name,
    commercialName: commercialName || name,
    eanCode,
    minimumSaleQuantity: defaultUnitMeasure.minimumSaleQuantity,
    maximumSaleQuantity: defaultUnitMeasure.maximumSaleQuantity,
    presentation: defaultUnitMeasure.presentation,
    price: defaultUnitMeasure.price,
    originalPrice: defaultUnitMeasure.originalPrice,
    hasDiscount: defaultUnitMeasure.hasDiscount,
    sku,
    stock,
    subcategoryId: Number(externalFamily.id),
    sourceId,
    taxRate,
    categoryName: externalCategory.name,
    subcategoryName: externalFamily.name,
    description,
    providerDisplayName: externalProvider.name,
    distributorName: externalDistributor.name,
    selectedUnitMeasure: {
      code: defaultUnitMeasure.code,
      default: defaultUnitMeasure.default,
      name: defaultUnitMeasure.description,
      presentation: defaultUnitMeasure.presentation,
      conversionFactor: defaultUnitMeasure.conversionFactor,
      price: defaultUnitMeasure.price,
      originalPrice: defaultUnitMeasure.originalPrice,
      minimumSaleQuantity: defaultUnitMeasure.minimumSaleQuantity,
      maximumSaleQuantity: defaultUnitMeasure.maximumSaleQuantity,
      hasDiscount: defaultUnitMeasure.hasDiscount,
    },
  };
};

export const navigationCatalogToCatalogueProducts = (
  catalog: INavigationCatalogDTO,
  treeItemType: 'subcategory' | 'category',
): ICatalogueProducts => ({
  [treeItemType]: {
    guid: catalog.treeItemId.toString(),
    name: catalog.description,
  },
  products: filterProducts(catalog.catalog.products),
  pagination: catalog.catalog.pagination,
});

export const catalogDetailResponseToProduct = (product: IProductDTO): IDetailProduct => {
  const activeUnitMeasures = product.unitMeasure.filter(uM => uM.isActive === true);
  if (activeUnitMeasures.length === 0) throw new Error(`There are no active unit measures for sku: ${product.sku}`);

  return {
    ...productDTOToIProduct(product),
    distributor: {
      id: product.externalDistributor.id,
      document: product.externalDistributor.document,
      name: product.externalDistributor.name,
    },
    unitMeasures: activeUnitMeasures.map(uM => ({
      code: uM.code,
      default: uM.default,
      name: uM.description,
      presentation: uM.presentation,
      conversionFactor: uM.conversionFactor,
      price: uM.price,
      originalPrice: uM.originalPrice,
      minimumSaleQuantity: uM.minimumSaleQuantity,
      maximumSaleQuantity: uM.maximumSaleQuantity,
      hasDiscount: uM.hasDiscount,
    })),
  };
};

export const listProductsToPaginatedProducts = ({ pagination, products }: IGetListProductsDTO): IPaginatedProducts => {
  const filteredProducts = filterProducts(products);
  return {
    products: filteredProducts,
    pagination,
  };
};

export const filterProducts = (products: Array<IProductDTO>) =>
  products
    .map(p => {
      try {
        return productDTOToIProduct(p);
      } catch (e) {
        return null;
      }
    })
    .filter(p => p !== null);
