import { ReactNode, Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SkeletonPlaceHolder } from '@insuma/mpp-ui/components/skeleton';
import { LAYOUT_IGNORED_ROUTES } from 'core/constants/routes.constants';
import { useMonitoring } from 'core/contexts/monitoring.context';
import useAppNavigation from 'core/hooks/app/use-app-navigation.hooks';
import useInitializeApp from 'core/hooks/app/use-initialize-app.hooks';
import useInitializeEvents from 'core/hooks/app/use-initialize-events';
import useInitializeUser from 'core/hooks/app/use-initialize-user';
import useScrollRestoration from 'core/hooks/app/use-scroll-restoration';
import LazyShoppingCartDrawer from 'screens/shopping-cart-drawer/lazy-shopping-cart-drawer';
import { MainHeader } from 'shared/components/main-components';
import LazyMainFooter from 'shared/components/main-components/main-footer/lazy-main-footer';
import LazyMainMenu from 'shared/components/main-components/main-menu/lazy-main-menu';
import { MobileLayoutVisibility } from 'shared/components/mobile-route-visibility';
import { AddressSelectorModal } from 'shared/components/mutiple-address';
import { SuspensePageLoading } from 'shared/components/suspense/suspense-page-loading';
import { decodeJWTToken, getTokenFromLocalStorage } from 'shared/utils/token.utils';

import './authenticated-layout.scss';

export const AuthenticatedLayout = ({ children }: { children: ReactNode }) => {
  const { pathname } = useLocation();
  const { onToggleCart, onToggleMenu } = useAppNavigation();
  const { loading: loadingProfile } = useInitializeUser();
  const monitoringInstance = useMonitoring();

  useInitializeApp();
  useInitializeEvents();
  useScrollRestoration();

  useEffect(() => {
    const token = getTokenFromLocalStorage();
    if (token) {
      const payload = decodeJWTToken(token);
      if (payload?.userId) {
        monitoringInstance.setUserId(payload.userId);
      }
    }
  }, [monitoringInstance]);

  return (
    <div className="authenticated-layout">
      <MobileLayoutVisibility blacklist={LAYOUT_IGNORED_ROUTES.HEADER}>
        <MainHeader onToggleMenu={onToggleMenu} onToggleCart={onToggleCart} />
      </MobileLayoutVisibility>

      <Suspense fallback={null}>
        <LazyMainMenu path={pathname} />
      </Suspense>
      <Suspense fallback={null}>{!loadingProfile && <LazyShoppingCartDrawer />}</Suspense>
      <AddressSelectorModal />
      <main id="app">
        <SkeletonPlaceHolder loading={loadingProfile} skeleton={<SuspensePageLoading />}>
          {children}
        </SkeletonPlaceHolder>
      </main>
      <MobileLayoutVisibility blacklist={LAYOUT_IGNORED_ROUTES.FOOTER}>
        <Suspense fallback={null}>
          <LazyMainFooter />
        </Suspense>
      </MobileLayoutVisibility>
    </div>
  );
};
