import { useState } from 'react';
import { ImageRoutes } from 'core/constants/image-routes';
import { ICurrency } from 'core/model/interfaces/currency.interface';
import { DiscountTag } from 'shared/components/discount-tag';
import { accessibleOnClick } from 'shared/utils/a11y.utils';
import { formatNumberWithCommas } from 'shared/utils/format-numbers.utils';

import './search-product-preview.scss';

export interface ISearchProductPreviewProps {
  productId: string;
  displayName: string;
  image: string;
  presentation: string;
  price: number;
  originalPrice: number;
  hasDiscount: boolean;
  currency: ICurrency;
  onDetailProduct: () => void;
}

export const SearchProductPreview = ({
  originalPrice,
  hasDiscount,
  displayName,
  image,
  presentation,
  price,
  currency,
  onDetailProduct,
}: ISearchProductPreviewProps) => {
  const [defaultImg, setDefaultImg] = useState(image);

  return (
    <article className="search-product-preview" {...accessibleOnClick(onDetailProduct)}>
      <div className="search-product-preview__image-container" style={{ display: hasDiscount ? 'block' : 'flex' }}>
        {hasDiscount && <DiscountTag />}
        <img
          className="search-product-preview__image"
          src={defaultImg || ImageRoutes.CATALOGUE_DEFAULT_PRODUCT}
          onError={() => setDefaultImg(ImageRoutes.CATALOGUE_DEFAULT_PRODUCT)}
          alt={displayName}
        />
      </div>
      <div className="search-product-preview__detail">
        <div className="search-product-preview__name">
          <span>{displayName}</span>
        </div>

        <div className="search-product-preview__description">
          <span>{presentation}</span>
        </div>

        <div>
          <span>
            {hasDiscount && (
              <span className="search-product-preview__price-discount">
                {currency?.symbol} {formatNumberWithCommas(price, 2)}
              </span>
            )}
            <span className={`search-product-preview__${hasDiscount ? 'discount' : 'price-normal'}`}>
              {currency?.symbol} {formatNumberWithCommas(originalPrice, 2)}
            </span>
          </span>
        </div>
      </div>
    </article>
  );
};
