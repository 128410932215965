import axios from 'axios';
import { ECustomerSource } from 'core/model/enums/customer.enum';
import { EServicesErrorCode } from 'core/model/enums/error-code.enum';
import { EProductOrigin } from 'core/model/enums/gtm.enum';
import { EProductType } from 'core/model/enums/product.enum';
import { ECartActions, ECartError } from 'core/model/enums/shopping-cart.enum';
import { IServiceError } from 'core/model/interfaces/error.interface';
import { ICartProduct } from 'core/model/interfaces/product.interface';
import { IShoppingCartGroups } from 'core/model/interfaces/shopping-cart.interface';
import type { TAppDispatch } from 'core/store';
import { loadCreditLimitsAction } from 'core/store/credits';
import { cloneDeepSimple } from 'shared/utils/object.utils';
import { getCartAction } from './async-thunks/get-cart';
import { EMPTY_SHOPPING_CART_AMOUNTS } from './constants';
import { ICartState, IChangeProductActionParams } from './types';

export const countTotalProductsInCart = (products: Array<{ quantity: number }>) =>
  products.map(p => p.quantity).reduce((curr, acc) => curr + acc, 0);

export const getCartProductsByUserSource = (customerSource: ECustomerSource, itemsGroups: IShoppingCartGroups) => {
  switch (customerSource) {
    case ECustomerSource.APUDEX:
    case ECustomerSource.DEX:
      return cloneDeepSimple(itemsGroups.generalItems.products);
    case ECustomerSource.HYBRID:
      return [...itemsGroups.alicorpItems.products, ...itemsGroups.alliedItems.products];
    default:
      return [];
  }
};

export const hasCustomerReachedMinSaleAmounts = (customerSource: ECustomerSource, itemsGroups: IShoppingCartGroups) => {
  if (customerSource === ECustomerSource.APUDEX || customerSource === ECustomerSource.DEX)
    return (
      itemsGroups.generalItems.amounts.price - itemsGroups.generalItems.amounts.discount >=
      itemsGroups.generalItems?.minimumSaleAmount
    );

  if (customerSource === ECustomerSource.HYBRID)
    return (
      itemsGroups.alliedItems.amounts.price - itemsGroups.alliedItems.amounts.discount >=
        itemsGroups.alliedItems.minimumSaleAmount &&
      itemsGroups.alicorpItems.amounts.price - itemsGroups.alicorpItems.amounts.discount >=
        itemsGroups.alicorpItems.minimumSaleAmount
    );
};

export const buildOptimisticCart = (
  hasAlreadyAdded: boolean,
  products: Array<ICartProduct>,
  metaArgs: IChangeProductActionParams,
) => {
  const newProducts = cloneDeepSimple(products);
  if (hasAlreadyAdded) {
    const index = newProducts.findIndex(product => metaArgs.sku === product.sku);
    newProducts[index] = {
      ...products[index],
      ...(metaArgs.origin === EProductOrigin.DETAIL_PRODUCT && metaArgs.product),
      quantity: metaArgs.quantity,
      selectedUnitMeasure: metaArgs.unitMeasure,
      metadata: metaArgs.metadata,
    };
  } else {
    newProducts.push({
      ...metaArgs.product,
      quantity: metaArgs.quantity,
      selectedUnitMeasure: metaArgs.unitMeasure,
      amounts: EMPTY_SHOPPING_CART_AMOUNTS,
      promos: [],
      reducedQuantity: 0,
      metadata: metaArgs.metadata,
    });
  }
  return newProducts;
};

export const buildOptimisticCartByGroups = (
  customerSource: ECustomerSource,
  hasAlreadyAdded: boolean,
  state: ICartState,
  metaArgs: IChangeProductActionParams,
) => {
  const itemGroups = cloneDeepSimple(state.itemsGroups);
  switch (customerSource) {
    case ECustomerSource.APUDEX:
    case ECustomerSource.DEX:
      itemGroups.generalItems.products = buildOptimisticCart(
        hasAlreadyAdded,
        state.itemsGroups.generalItems?.products,
        metaArgs,
      );
      break;
    case ECustomerSource.HYBRID:
      if (metaArgs.product.sourceId === EProductType.ALICORP)
        itemGroups.alicorpItems.products = buildOptimisticCart(
          hasAlreadyAdded,
          state.itemsGroups.alicorpItems?.products,
          metaArgs,
        );
      if (metaArgs.product.sourceId === EProductType.ALLIED)
        itemGroups.alliedItems.products = buildOptimisticCart(
          hasAlreadyAdded,
          state.itemsGroups.alliedItems?.products,
          metaArgs,
        );
      break;
    default:
      break;
  }
  return itemGroups;
};

export const isProductAlreadyInCart = (
  customerSource: ECustomerSource,
  itemsGroups: IShoppingCartGroups,
  sku: string,
): boolean => {
  const products = getCartProductsByUserSource(customerSource, itemsGroups);
  return products.some(p => p.sku === sku);
};

export const handleErrorOnChangingProductQuantity = (
  error: unknown,
  dispatch: TAppDispatch,
  cartActionParams?: { cartAction: ECartActions },
) => {
  if (axios.isAxiosError<IServiceError>(error)) {
    if (error.response?.data?.error?.code === EServicesErrorCode.CART_STOCK_EXCEED) {
      dispatch(getCartAction(cartActionParams));
    }
    if (error.response?.data?.error?.code === EServicesErrorCode.CART_DEBT_BLOCKED) {
      dispatch(loadCreditLimitsAction());
      dispatch(getCartAction());
    }
  }
  throw new Error(ECartError.UNEXPECTED_ERROR);
};

export const hasReducedProductsByCustomerSource = (
  customerSource: ECustomerSource,
  itemsGroups: IShoppingCartGroups,
) => {
  const products = getCartProductsByUserSource(customerSource, itemsGroups);
  return products?.some(product => product.reducedQuantity > 0);
};

export const getHasReducedProducts = (productsInCart: Array<ICartProduct>) =>
  productsInCart?.some(product => product.reducedQuantity > 0);

export const removeProductOptimistically = (sku: string, products: Array<ICartProduct>) => {
  const filteredProducts = cloneDeepSimple(products);
  return filteredProducts.filter(product => sku !== product.sku);
};

export const removeProductOptimisticallyByGroups = (
  customerSource: ECustomerSource,
  state: ICartState,
  sku: string,
  sourceId: number,
): IShoppingCartGroups => {
  const itemGroups = cloneDeepSimple(state.itemsGroups);
  switch (customerSource) {
    case ECustomerSource.APUDEX:
    case ECustomerSource.DEX:
      itemGroups.generalItems.products = removeProductOptimistically(sku, itemGroups.generalItems.products);
      break;
    case ECustomerSource.HYBRID:
      if (sourceId === EProductType.ALICORP)
        itemGroups.alicorpItems.products = removeProductOptimistically(sku, itemGroups.alicorpItems.products);
      if (sourceId === EProductType.ALLIED)
        itemGroups.alliedItems.products = removeProductOptimistically(sku, itemGroups.alliedItems.products);
      break;
    default:
      break;
  }
  return itemGroups;
};
