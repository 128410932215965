import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { regexpMarkup } from 'core/constants/validation';

export interface IMarkupFormatterProps {
  children: string;
}

export const MarkupFormatter = ({ children }: IMarkupFormatterProps): JSX.Element => {
  const getText = (): string | JSX.Element | Array<JSX.Element> => {
    const result = children
      .replace(regexpMarkup.MARKUP_BOLD, '<b style="font-family: AlicorpSans-Semibold;">$1</b>')
      .replace(regexpMarkup.MARKUP_MOBILE_PHONE, '<a href="tel:$1"><b><u>$1</u></b></a>')
      .replace(regexpMarkup.MARKUP_EMAIL, '<a href="mailto:$1"><b><u>$1</u></b></a>');
    // Sanitize html to prevent XSS (Cross-site scripting), you can test it bellow
    // console.log('purify', DOMPurify.sanitize(result+'<iframe src=javascript:alert("xss")></iframe>'))
    return parse(DOMPurify.sanitize(result));
  };

  return <>{getText()}</>;
};
