import { ECurrencies } from 'core/model/enums/currency.enum';
import { IDetailProduct, IProduct } from 'core/model/interfaces/product.interface';
import { pushObjectDataLayer } from './gtm.events';

interface ITriggerSelectedProduct {
  product: IProduct;
  listName?: string;
  origin: string;
}

interface ITriggerDetailProduct {
  productDetail: IDetailProduct;
  listName?: string;
}

export const triggerSelectedProduct = ({ product, listName = 'null', origin }: ITriggerSelectedProduct) => {
  const finalProduct = {
    item_name: product.name,
    item_id: product.sku,
    price: product.price,
    item_brand: product.providerDisplayName,
    item_category: product.categoryName,
    item_category2: product.subcategoryName,
    item_list_id: listName,
    item_list_name: listName,
    procedencia: product.providerDisplayName,
    index: 1,
    quantity: 1,
    product_ean: product.eanCode,
    product_sku: product.sku,
    metadata: origin,
    dex: product.distributorName,
    aplica_descuento: product.hasDiscount,
    presentacion: product.presentation,
  };

  const data = {
    event: 'select_item',
    ecommerce: {
      currency: product?.currency.symbol || ECurrencies.PEN,
      items: [finalProduct],
    },
  };

  pushObjectDataLayer(data, triggerSelectedProduct.name);
};

export const triggerDetailProduct = ({ productDetail, listName = 'null' }: ITriggerDetailProduct) => {
  const finalProduct = {
    item_name: productDetail.name,
    item_id: productDetail.sku,
    price: productDetail.price,
    item_brand: productDetail.providerDisplayName,
    item_category: productDetail.categoryName,
    item_category2: productDetail.subcategoryName,
    item_list_id: listName,
    item_list_name: listName,
    procedencia: productDetail.providerDisplayName,
    index: 1,
    quantity: 1,
    product_ean: productDetail.eanCode,
    product_sku: productDetail.sku,
    dex: productDetail.distributorName,
    aplica_descuento: productDetail.hasDiscount,
    presentacion: productDetail.presentation,
  };

  const data = {
    event: 'view_item',
    ecommerce: {
      currency: productDetail?.currency.symbol || ECurrencies.PEN,
      items: [finalProduct],
    },
  };

  pushObjectDataLayer(data, triggerDetailProduct.name);
};
