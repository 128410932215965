export const regexp = {
  DNI: /^[0-9]{8}$/g,
  RUC: /^[0-9]{11}$/g,
  LETTERS_AND_SPACE: /^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\s]*$/g,
  ALFANUMERICS: /^[A-Za-z0-9]*$/g,
  NUMBERS: /^[0-9]*$/g,
  // eslint-disable-next-line no-useless-escape
  ALPHANUMERIC_SYMBOLS_NOSPACE: /^([A-z0-9!@#$%^&*().,<>{}[\]<>?_=+\-|;:\'\"\/])*[^\s]\1*$/,
};

export const regexpMarkup = {
  MARKUP_BOLD: /\*(.*?)\*/g,
  MARKUP_MOBILE_PHONE: /\b(([9])(\d{2})-? ?(\d{3})-? ?(\d{3}))\b/g,
  MARKUP_EMAIL:
    // eslint-disable-next-line no-control-regex
    /((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))/g,
};
